export const Routes = {
  backendRoot: process.env.REACT_APP_BACKEND_ROOT,
  index: "/",
  home: "/",
  aboutUs: "/aboutus/aboutus",
  governance: "/aboutus/governance",
  history: "/aboutus/history",
  ourTeam: "/aboutus/ourteam",
  presidentMessage: "/aboutus/president",
  contact: "/contact",
  events: "/events",
  freshmen: "/freshmen",
  studentfunds: "/services/studentfunds",
  councilfunding: "/services/councilfunding",
  resiliencefund: "/services/resiliencefund",
  zoomlicense: "/services/zoomLicense",
  publicitymanagement: "https://forms.office.com/Pages/ResponsePage.aspx?id=Xu-lWwkxd06Fvc_rDTR-gnQf1jdn-oNNuI1ZYSNB3CpUOE40TERGUDhMTzM4NlFaWVVFNk8zTkxBMi4u",
  joinus: "/joinus",
  form: "/contact/submit_feedback/",
};
